<template>
  <div class="mainform-dilog">
    <!-- 我的报价 -->
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData" ></list-search>
    <div class="tableList-deep">
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction"></global-table>
    </div>
    <dialog-box ref="queryDialog" width="80%" :dialogShow="dialogShow" :ID="ID" @handleClose='handleClose' title="报价" componentName="InquiryDetailDialog" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>
  </div>
</template>
<script>
import { requestForm, request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import Component from '@/assets/js/components.js'
import { searchList, searchData, tableField, tableData } from './js/Myprice.js'
export default {
  components: Component.components,
  name: 'Myprice',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      dialogShow: false,
      ID: '',
      tableField: tableField,
      tableData: tableData,
      childData: { searchList: searchList, searchData: searchData },
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      }
    }
  },
  created () {
    this.myQuoteList()
  },
  methods: {
    // 我的报价列表
    myQuoteList () {
      let ksTime = ''
      let jsTime = ''
      if (this.childData.searchData.createDate) {
        ksTime = dayjs(this.childData.searchData.createDate[0]).format('YYYY-MM-DD HH:mm:ss')
        jsTime = dayjs(this.childData.searchData.createDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        code: this.childData.searchData.code,
        itemName: this.childData.searchData.itemName,
        type: this.childData.searchData.type,
        startTime: ksTime,
        endTime: jsTime,
        company: this.childData.searchData.company,
        status: this.childData.searchData.status
      }
      requestForm('/api/inquiry/myQuoteList?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({
          path: '/SalesLeads/InquiryDetails',
          query: {
            id: data.id,
            isQuote: data.isQuote
          }
        })
      } else {
        this.dialogShow = true
        this.ID = data.id
      }
    },
    // 点击报价弹框里的确定按钮
    action () {
      var onelist = this.$refs.queryDialog.$refs.content.$refs.form.model
      var bjyxq = this.$refs.queryDialog.$refs.content.$refs.form.model.bjyxq
      if (onelist.quoteDesc === undefined || onelist.bjyxq === undefined) {
        this.$message({
          showClose: true,
          message: '请完善报价信息',
          type: 'warning'
        })
      } else {
        if (bjyxq) {
          this.$refs.queryDialog.$refs.content.$refs.form.model.priceStartTime = dayjs(bjyxq[0]).format('YYYY-MM-DD HH:mm:ss')
          this.$refs.queryDialog.$refs.content.$refs.form.model.priceEndTime = dayjs(bjyxq[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        for (var i = 0; i < onelist.list.length; i++) {
          var objs = onelist.list[i]
          for (var key in objs) {
            if (!objs[key].value) {
            }
          }
        }
        const obj = {
          inquiryId: this.ID,
          inquiryMaterialQuoteVoList: onelist.list,
          priceStartTime: onelist.priceStartTime,
          priceEndTime: onelist.priceEndTime,
          quoteDesc: onelist.quoteDesc,
          supplierId: ''
        }
        request('/api/inquiry/supplierQuote', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            })
            this.myQuoteList()
            this.dialogShow = false
          }
        })
      }
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myQuoteList()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myQuoteList()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myQuoteList()
    }
  }

}

</script>
<style scoped lang="scss">
  @import '@/assets/css/tablePadding.scss';
  .mainform-dilog{
    :deep(.dialogBox .el-overlay .el-dialog){
      width: 80% !important;
    }
    :deep(.dialogBox .el-overlay .el-dialog .el-dialog__body){
      padding: 20px 40px;
    }
  }
  .tableList-deep{
    padding: 0 30px !important;
  }
  .tableList-deep .tableList{
    padding: 0;
  }
</style>
