import md5 from 'js-md5'
export var searchList = [
  { labe: '询价编号', code: 'code', type: 'input' },
  { labe: '项目名称', code: 'itemName', type: 'input' },
  {
    labe: '询价类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '单一供应商询价', value: '1' },
      { label: '多家供应商询价', value: '2' },
      { label: '普通竞价', value: '3' },
      { label: '反向竞价', value: '4' }
    ]
  },
  {
    labe: '报价日期',
    code: 'createDate',
    type: 'daterange'
  },
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '待报价', value: '1' },
      { label: '已报价', value: '2' },
      { label: '已结束', value: '3' }
    ]
  }
]
export var searchData = {
  inquirynumber: '',
  projectname: '',
  inquirytype: '',
  status: '',
  company: '',
  createDate: ''
}
export var tableField = [
  { label: '询价编号', code: 'inquiry_code', type: 'input', width: '50%' },
  { label: '项目名称', code: 'inquiryDesc', type: 'input', width: '80%' },
  {
    label: '询价类型',
    code: 'inquiryType',
    type: 'function',
    width: '50%',
    handle: (index, data) => {
      return data.inquiryType === 1 ? '单一供应商询价'
        : data.inquiryType === 2 ? '多家供应商询价' : data.inquiryType === 3 ? '普通竞价'
          : data.inquiryType === 4 ? '反向竞价' : ''
    }
  },
  { label: '公司名称', code: 'company', type: 'input', width: '70%' },
  {
    label: '报价时间',
    code: 'quoteTime',
    type: 'input',
    width: '80%'
  },
  {
    label: '状态',
    code: 'isQuote',
    type: 'function',
    width: '40%',
    handle: (index, data) => {
      const color = md5(data.isQuote + '').substring(0, 6)
      const label = data.isQuote === 1 ? '待报价'
        : data.isQuote === 2 ? '已报价' : data.isQuote === 3 ? '已结束' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '40%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      },
      {
        actionLabel: '报价',
        operationButton: 'offer',
        stateField: 'isQuote',
        stateSymbol: '=',
        stateValue: '1'
      }
    ]
  }
]

export var tableData = []
